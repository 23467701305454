import classNames from "classnames";
import { useState, useCallback } from "react";
import Navbar from "./components/Navbar";

const imgs = [
  "https://images.unsplash.com/photo-1642871358528-949e171101d4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY0NDkzNzc1MA&ixlib=rb-1.2.1&q=80&w=1080",
  "https://images.unsplash.com/photo-1642213461617-9bcfc2c8e76c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY0NDkzODkxNQ&ixlib=rb-1.2.1&q=80&w=1080",
  "https://source.unsplash.com/random",
];

export default function App() {
  const [index, setIndex] = useState(0);

  const renderImage = useCallback(
    (arr) => {
      console.log(index);
      return arr.map((img, i) => {
        return (
          <li
            key={i}
            className={classNames("slide", { "opacity-100": index === i })}
          >
            <img src={img} alt="" />
          </li>
        );
      });
    },
    [index]
  );

  return (
    <>
      <Navbar />
      <section>
        <div className="carousel">
          <button
            className="btn carousel-button prev"
            onClick={() =>
              index === 0 ? setIndex(imgs.length - 1) : setIndex(index - 1)
            }
          >
            &#x2190;
          </button>
          <button
            className="btn carousel-button next"
            onClick={() =>
              index >= imgs.length - 1 ? setIndex(0) : setIndex(index + 1)
            }
          >
            &#x2192;
          </button>
          <ul>{renderImage(imgs)}</ul>
        </div>
      </section>
    </>
  );
}
