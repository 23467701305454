import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import { AiOutlineMenu } from "react-icons/ai";

import useWindowDimensions from "../hooks/useWindowDimensions";

export default function Navbar() {
  const { width } = useWindowDimensions();
  const [toggle, setToggle] = useState(false);
  const nav = useRef(null);

  const display = toggle || width >= 768;

  useEffect(() => {
    width <= 768 && setToggle(false);
  }, [width]);

  const onToggleNav = () => {
    setToggle(!toggle);
  };

  return (
    <div className="p-4 bg-black text-white w-full md:flex md:justify-between">
      <nav className="flex gap-4">
        {/* Hamburgur menu */}
        <button className="md:hidden" id="navToggle" onClick={onToggleNav}>
          <AiOutlineMenu />
        </button>
        <h2>
          Portfolio{" "}
          <span className="text-blue-300 text-lg font-bold">Chong How</span>
        </h2>
      </nav>
      <nav
        className={classNames("h-0 transition-all duration-300", {
          "h-40": toggle,
        })}
      >
        <ul
          ref={nav}
          className={classNames("md:flex md:gap-4", { hidden: !display })}
        >
          <li className="nav-link">Home</li>
          <li className="nav-link">Product</li>
          <li className="nav-link">About</li>
          <li className="nav-link">Contact</li>
        </ul>
      </nav>
    </div>
  );
}
